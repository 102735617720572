export { PathRoutes } from './routes';

export * from './branches';
export * from './sarlaft';
export enum Modules {
  Queries = 'queries',
  Renovation = 'renovation'
}

export enum Validities {
  current = 'current',
  next = 'next',
  all = 'all'
}

export const validities = [
  { name: 'Todos', value: 'Todos' },
  { name: 'Mes actual', value: 'Mes actual' },
  { name: 'Próximo mes', value: 'Próximo mes' }
];

export enum PersonRoles {
  taker = 'Tomador',
  insured = 'Asegurado',
  beneficiary = 'Beneficiario',
  beneficiaryDental = 'Beneficiario odontologico',
  conductor = ''
}

export enum Groupings {
  AUT = 'AUT',
  EMP = 'EMP',
  HOG = 'HOG',
  SAL = 'SAL',
  TRA = 'TRA',
  VID = 'VID'
}

export enum NotificationTypes {
  POLICY_REPORT = 'POLICY_REPORT',
  RENOVATION_REPORT = 'RENOVATION_REPORT',
  CERTIFICATES_REPORT = 'CERTIFICATES_REPORT',
  CERTIFICATES_RENOVATION_REPORT = 'CERTIFICATES_RENOVATION_REPORT',
  CLAIMS_REPORT = 'CLAIMS_REPORT',
  MASSIVE_RENOVATION_REPORT = 'MASSIVE_RENOVATION_REPORT',
  COLLECTIVE_PDF_DOWNLOAD_REPORT = 'COLLECTIVE_PDF_DOWNLOAD_REPORT'
}

export const SarlaftHabeasdataTypes = {
  ACTUALIZADO: '0',
  SIN_VERIFICAR: '1',
  ACTUALIZANDO: '2',
  NOT_SHOW: '3'
};

export const MAX_DAYS_RENOVATION = 1;

export const STATUS_CODE_TO_RETRY = [503, 404];

export enum FilesPersonsTypes {
  SARLAFT = 'SARLAFT',
  HABEAS_DATA = 'HABEAS_DATA'
}

export const EXTENSIONS_ACCEPTED = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'];
export const EXTENSIONS_TYPES_ACCEPTED = ['pdf', 'png', 'gif', 'jpg', 'jpeg', 'doc', 'docx'];

export const IP = [1, 1, 1, 1].join('.');

export const SHOW_TABS_PRODUCTS_INDIVIDUAL = ['900742', '900746', '900758', '10001', '10000'];

export const PRODUCTS_DISABLE_RENEW_BUTTON = [
  //*__________ AUTOS COLECTIVOS _______
  { product: '6041', modality: [0] },
  { product: '6043', modality: [0] },
  { product: '6045', modality: [0] },
  { product: '6049', modality: [0] },
  { product: '6046', modality: [0] },
  { product: '6042', modality: [0] },
  { product: '6047', modality: [0] },
  { product: '6048', modality: [0] },
  { product: '900753', modality: [0] },
  //*_______EMPRESARIALES_____________
  { product: 'LGP', modality: [0] },
  { product: '900746', modality: [0] },
  //*Aclaración: se realiza esta duplicacion de producto 10024 ya que hata la fecha solo el poducto 900742 puede ser renovado
  //*los demas no deben validar la cantidad de riesgos y por ende tienen que estar en managerenewals
  { product: '10024', modality: [3, 4, 7, 8, 9, 10, 11], verifyRisks: false }, //*Con mas de 10 certificados
  // { product: '10024', modality: [3, 4, 7, 8, 9, 10, 11], verifyRisks: true }, //*Con mas de 10 certificados
  //*Aclaración: se realiza esta duplicacion de producto 900742 debido a que en produccion solo se puede renovar la modalidad 4
  //*mientras cumpla con el rango de risk>0 && risk<=10, pero las demas modalidades se tienen que estas en managerenewal
  { product: '900742', modality: [3, 7, 8, 9, 10], verifyRisks: false }, //*Con mas de 10 certificados
  { product: '900742', modality: [4], verifyRisks: true }, //*Con mas de 10 certificados
  //*_________HOGAR____________________
  { product: '6071', modality: [0] },
  { product: '10003', modality: [0] },
  //*____________SALUD__________________
  { product: 'H1', modality: [0] },
  { product: 'Z1', modality: [0] },
  { product: 'ADU', modality: [0] },
  { product: 'E1', modality: [0] },
  { product: 'T1', modality: [0] },
  { product: 'SE', modality: [0] },
  //*_____________TRASPORTE_____________
  { product: '70107', modality: [0] },
  { product: '70108', modality: [0] },
  //*____________VIDA___________________
  // { product: '6029', modality: [0] },
  // { product: '6024', modality: [0] },
  { product: '6026', modality: [0] }
  // { product: '900720', modality: [0] }, //*Analiza poliza nueva
  // { product: '6025', modality: [0] },
  // { product: '6028', modality: [0] },
  // { product: '6023', modality: [0] },
  // { product: '7468', modality: [0] },
  // { product: '900719', modality: [0] }, //*Analiza poliza nueva
  // { product: '7467', modality: [0] },
  // { product: '900721', modality: [0] },
  // { product: '6052', modality: [0] },
  // { product: '7469', modality: [0] }
];
