export const URL_OFFICE =
  process.env.REACT_APP_URL_OFFICE || 'https://oficinaenlineauat.hdiseguros.com.co/';

export * from './constants';
export * from './validations';
export * from './renovations';

export const DATE_FORMAT = 'dd/MMM/yyyy';

export const ENDOSOS_MAX_SMLV = 17.5;
